import React, { useState } from 'react';

import scoreData from 'data/leagueData/score.json'
import { ROUNDS, REGULAR_ROUND_KEY } from 'leagueConstants'

import CalendarTable from "components/Tables/CalendarTable";
import CalendarSetup from "components/Setups/CalendarSetup";

export default function CalendarPage() {
  const [currentPage, setCurrentPage] = useState(3);
  const [currentRound, setCurrentRound] = useState(REGULAR_ROUND_KEY);
  const handleRoundChange = (round) => {
    setCurrentPage(1)
    setCurrentRound(round)
  }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  return (
    <>
      <div className="flex flex-wrap bg-cover pt-32">
        <div className="w-full mb-12 px-4">
          <div className="rounded-t mb-0 pb-4 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                <h3 className="font-bold text-lg text-main-dark-opacity uppercase">Resultados</h3>
              </div>
            </div>
          </div>
          <CalendarSetup rounds={ROUNDS} 
              pages={Array.from({ length: ROUNDS[currentRound].num }, (_, i) => i + 1)}
              handlePageChange={handlePageChange}
              handleRoundChange={handleRoundChange}
              selectedRound={currentRound}
              selectedPage={currentPage}
          />
          <CalendarTable round={currentRound} 
              page={currentPage}
              data={scoreData}
          />
        </div>
      </div>
    </>
  );
}
