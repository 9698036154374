import teams from 'data/leagueData/teamsFullInfo.json'
import teamsRR from 'data/leagueData/teamsFullInfoRR.json'
import calendar from 'data/leagueData/calendar.json'
import scoreData from 'data/leagueData/score.json'
import statsKeys from "data/stats/statsKeys.json";
import { CHAMPIONS, OFFENSIVE_KEY, ROUND_ROBIN_KEY, REGULAR_ROUND_KEY } from 'leagueConstants'

const teamLogos = {
    "Espartanas": require('assets/leagueImg/Espartanas.png'),
    "Panteras": require('assets/leagueImg/Panteras.png'),
    "Vikingas": require('assets/leagueImg/Vikingas.png'),
    "Tropicales": require('assets/leagueImg/Tropicales.png'),
    "Gladiadoras": require('assets/leagueImg/Gladiadoras.png'),
    "Goodies": require('assets/leagueImg/Goodies.png'),
    "Murallas": require('assets/leagueImg/Murallas.png'),
    "Las Panas": require('assets/leagueImg/LasPanas.png'),
    "Aguilas": require('assets/leagueImg/Aguilas.png'),
    "Empty": require('assets/img/ill_header.png')
}
const teamMap = {
    [ROUND_ROBIN_KEY]: teamsRR, 
    [REGULAR_ROUND_KEY]: teams
}

export const getTeamLogo = (team) => {
    return teamLogos[team] || teamLogos.Empty
}

export const getTeamFromLocation = (locationData) => {
    let hash = locationData.hash
    hash = hash.replace('#', '')
    return hash === 'LasPanas' ? 'Las Panas' : hash
}

export const getTeamProperty = (teamKey, property) => {
    const t = teams.find(t => t.key === teamKey)
    return (t && t[property]) || ""
}

export const processTeamKeyForPath = (teamKey) => {
    return teamKey.replace(" ", "")
}

export const getTeamsOrderedByName = () => {
    const nt = JSON.parse(JSON.stringify(teams))
    return nt.sort((a, b) => a.key.localeCompare(b.key))
}

export const getFirstChampionOfSection = (section) => {
    return (CHAMPIONS[section] && CHAMPIONS[section][0] && CHAMPIONS[section][0].key) || '-'
}

export const getHeaderForStatsTable = (section, champion, addChampionColumn = false) => {
    const keys = section === OFFENSIVE_KEY && addChampionColumn ? JSON.parse(JSON.stringify(statsKeys[section].extended))
        : JSON.parse(JSON.stringify(statsKeys[section][champion]))
    if (addChampionColumn) keys.push({key: 'champion', name: 'champion'})
    return keys
}

export const getChampionName = (section, champion) => {
    return CHAMPIONS[section].find(c => c.key === champion).name
}

export const getNextGame = () => {
    const currentDate = new Date()
    const allGames = []
    Object.values(calendar).forEach(v => allGames.push(...Object.values(v)))
    for (const round of allGames) {
        const dateOfRound = new Date(round[0].basicDate)
        if (dateOfRound < currentDate) continue
        return round
    }
}

export const getFirstTeam = () => {
    const t = getTeamsOrderedByName()
    return t[0].key
}

export const extendCalendarData = () => {
    const newCalendar = JSON.parse(JSON.stringify(calendar))
    for (const round in calendar) {
        for (const gameDay in calendar[round]) {
            newCalendar[round][gameDay].map((g, i) => {
                g.isGameInPast = isPastGame(g.basicDate)
                if (g.isGameInPast) {
                    g.hour = scoreData[round][gameDay][i].hour
                }
                return g
            })
        }
    }
    return newCalendar
}

export const isPastGame = (date) => {
    const currentDate = new Date()
    const gameDate = new Date(date)
    return (gameDate < currentDate) 
}

export const getTeamRecap = (team, round = REGULAR_ROUND_KEY) => {
    let index
    const selectedTeam = teamMap[round].find((t, i) => {
        const isTeam = t.key === team
        index = (isTeam) ? i : null
        return isTeam
    })
    const ppPerRound = {
        [ROUND_ROBIN_KEY]: 5,
        [REGULAR_ROUND_KEY]: 8
    }

    return selectedTeam ? {
        ...selectedTeam,
        pp: ppPerRound[round] - parseInt(selectedTeam.JJ),
        position: index + 1
    } : {}
}

export const getGamesOfTeam = (team, round) => {
    const gamesOfTeam = []
    
    for (const day in scoreData[round]) {
        scoreData[round][day].forEach(g => {
            if ((g.visitor === team || g.homeClub === team) && g.hour.includes(" - ")) {
                gamesOfTeam.push(g)
            }
        })
    }

    return gamesOfTeam
}

export const isInRoundRobin = (team) => {
    return teamMap[ROUND_ROBIN_KEY].find((t) => t.key === team)
}