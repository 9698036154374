/*eslint-disable*/
import React, {useRef} from "react";
import { Rerousel } from 'rerousel';

import LeagueNavbar from "components/Navbars/LeagueNavbar.js";
// import CardTeam from "components/Cards/CardTeam.js";
// import NextGameHeader from "components/Setups/NextGameHeader"
// import RecapTable from "components/Tables/RecapTable"
import PodiumTable from "components/Tables/PodiumTable"
import PrevWinnersTable from "components/Tables/PrevWinnersTable"
import PrevWinnersPerTeamTable from "components/Tables/PrevWinnersPerTeamTable"
import KickingStats from "components/Stats/KickingStats.js";
import FieldingStats from "components/Stats/FieldingStats.js";
import PitchingStats from "components/Stats/PitchingStats.js";
import ScrollToTop from "components/ScrollToTop";

import { getLeagueStats } from "data/statCardsUtils.js";
import pictures from "data/leagueData/pictures";

import {SECTION_TITLE, PITCHING_KEY, OFFENSIVE_KEY, DEFENSIVE_KEY, ROUND_ROBIN_KEY} from "leagueConstants"

export default function Index() {
  const ref = useRef(null);
  const stats = getLeagueStats()

  return (
    <>
      <section>
        <div className="min-h-screen bg-cover bg-no-repeat"
            style={{
              backgroundImage: "url(" + require("assets/leagueImg/background.jpeg").default + ")",
              marginTop: "-56px"
            }}
        > 
          <ScrollToTop />
          <LeagueNavbar />
          <div className="pt-40">
          <div>
              <div className="px-4 pt-6">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-bold text-2xl text-main-dark-opacity uppercase">PODIO 2024</h3>
                  </div>
                </div>
              </div>
              <PodiumTable stats={stats} />
            </div>
            {/* <div>
              <div className="px-4">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-bold text-2xl text-main-dark-opacity uppercase">PRÓXIMOS PARTIDOS</h3>
                  </div>
                </div>
              </div>
              <NextGameHeader stats={stats}/>
            </div> */}
            {/* <hr className="my-6 mx-auto border-main-medium w-3/4" />
            <div>
              <div className="px-4 pt-6">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-bold text-2xl text-main-dark-opacity uppercase">EQUIPOS ROUND ROBIN</h3>
                  </div>
                </div>
              </div>
              <CardTeam round={ROUND_ROBIN_KEY}/>
            </div> */}
            {/* <hr className="my-6 mx-auto border-main-medium w-3/4" />
            <div>
              <div className="px-4 pt-6">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-bold text-2xl text-main-dark-opacity uppercase">RESUMEN</h3>
                  </div>
                </div>
              </div>
              <RecapTable stats={stats} />
            </div> */}
            <hr className="my-6 mx-auto border-main-medium w-3/4" />
            <div>
              <div className="px-4 pt-6">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-bold text-2xl text-main-dark-opacity uppercase">LÍDERES - ROUND ROBIN</h3>
                  </div>
                </div>
              </div>
              <div className="px-2">
                <h4 className="px-2 pt-6 font-bold text-lg text-main-dark-opacity uppercase">{SECTION_TITLE[PITCHING_KEY]}</h4>
                <PitchingStats style={{padding: "pt-2"}} round={ROUND_ROBIN_KEY}/>
                <h4 className="px-2 pt-6 font-bold text-lg text-main-dark-opacity uppercase">{SECTION_TITLE[DEFENSIVE_KEY]}</h4>
                <FieldingStats style={{padding: "pt-2", width: "w-3/12"}} round={ROUND_ROBIN_KEY}/>
                <h4 className="px-2 pt-6 font-bold text-lg text-main-dark-opacity uppercase">{SECTION_TITLE[OFFENSIVE_KEY]}</h4>
                <KickingStats style={{padding: "pt-2"}} round={ROUND_ROBIN_KEY}/>
              </div>
            </div>
            <hr className="my-6 mx-auto border-main-medium w-3/4" />
            {/* <div>
              <div className="px-4 pt-6">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-bold text-2xl text-main-dark-opacity uppercase">EQUIPOS RONDA REGULAR</h3>
                  </div>
                </div>
              </div>
              <CardTeam />
            </div>
            <hr className="my-6 mx-auto border-main-medium w-3/4" /> */}
            <div>
              <div className="px-4 pt-6">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-bold text-2xl text-main-dark-opacity uppercase">LÍDERES - RONDA REGULAR</h3>
                  </div>
                </div>
              </div>
              <div className="px-2">
                <h4 className="px-2 pt-6 font-bold text-lg text-main-dark-opacity uppercase">{SECTION_TITLE[PITCHING_KEY]}</h4>
                <PitchingStats style={{padding: "pt-2"}}/>
                <h4 className="px-2 pt-6 font-bold text-lg text-main-dark-opacity uppercase">{SECTION_TITLE[DEFENSIVE_KEY]}</h4>
                <FieldingStats style={{padding: "pt-2", width: "w-3/12"}}/>
                <h4 className="px-2 pt-6 font-bold text-lg text-main-dark-opacity uppercase">{SECTION_TITLE[OFFENSIVE_KEY]}</h4>
                <KickingStats style={{padding: "pt-2"}}/>
              </div>
            </div>
            <hr className="my-6 mx-auto border-main-medium w-3/4" />
            <div>
              <div className="px-4 pt-6">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-bold text-2xl text-main-dark-opacity uppercase">PALMARÉS</h3>
                  </div>
                </div>
              </div>
              <div className="px-2">
                <h4 className="px-2 pt-6 font-bold text-lg text-main-dark-opacity uppercase">Por años</h4>
                <PrevWinnersTable />
                <h4 className="px-2 pt-6 font-bold text-lg text-main-dark-opacity uppercase">Por equipos</h4>
                <PrevWinnersPerTeamTable />
              </div>
            </div>
            <hr className="my-6 mx-auto border-main-medium w-3/4" />
            <div>
              <div className="px-4 pt-6">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h3 className="font-bold text-2xl text-main-dark-opacity uppercase">RECUERDOS</h3>
                  </div>
                </div>
              </div>
              <div className="py-6">
                <Rerousel itemRef={ref} stop={true}>
                  {pictures.map(n => 
                    (<div key={n} className="flex justify-center items-center w-150-px h-200-px mx-4-px">
                        <img ref={ref} src={n}
                        alt="..."
                        className="test w-full"/>
                    </div>))
                  }
                </Rerousel>
              </div>
            </div>
          </div>
          
        </div>
      </section>
    </>
  );
}
