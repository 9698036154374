import teamTotalStatsRegular from 'data/stats/teamStats.json'
import teamTotalStatsRR from 'data/stats/teamStatsRR.json'
import leagueTotalStats from 'data/stats/leagueTotals.json'
import { REGULAR_ROUND_KEY } from 'leagueConstants'

const stats = {
    ij: {
        new: "",
        title: 'IJ',
        icon: 'fas fa-hashtag',
        color: 'bg-indigo-500',
        tooltip: 'Innings Jugados'
    },
    ab: {
        new: "",
        title: 'VP',
        icon: 'fas fa-square',
        color: 'bg-yellow-500',
        tooltip: 'Veces al Pateo'
    },
    avg: {
        new: "",
        title: 'AVG',
        icon: 'fas fa-person-running',
        color: 'bg-red-500',
        tooltip: 'Average'
    },
    fp: {
        new: "",
        title: 'FP',
        icon: 'fas fa-shield-halved',
        color: 'bg-purple-500',
        tooltip: 'Porcentaje de Fildeo'
    },
    era: {
        new: "",
        title: 'ERA',
        icon: 'fas fa-check',
        color: 'bg-teal-500',
        tooltip: 'Efectividad en el pitcheo'
    },
    k: {
        new: "",
        title: 'K',
        icon: 'fas fa-k',
        color: 'bg-orange-500',
        tooltip: 'Ponches'
    },
    one23: {
        new: "",
        title: '123',
        icon: 'fas fa-3',
        color: 'bg-pink-500',
        tooltip: 'Innings de 123 outs'
    },
    slg: {
        new: "",
        title: 'SLG',
        icon: 'fas fa-futbol',
        color: 'bg-lightBlue-500',
        tooltip: 'Slugging'
    }
}

export const getLeagueStats = (round = REGULAR_ROUND_KEY) => {
    const newStats = JSON.parse(JSON.stringify(stats))

    newStats.ab.new = leagueTotalStats[round].ab
    newStats.avg.new = leagueTotalStats[round].avg.toFixed(3).replace('0.', '.')
    newStats.slg.new = leagueTotalStats[round].slg.toFixed(3).replace('0.', '.')
    
    newStats.fp.new = (leagueTotalStats[round].fp * 100).toFixed(1)
    newStats.ij.new = parseInt(leagueTotalStats[round].ij)
    
    newStats.era.new = leagueTotalStats[round].era.toFixed(2)
    newStats.k.new = leagueTotalStats[round].k
    newStats.one23.new = leagueTotalStats[round].one23

    return newStats
}

export const getTeamStats = (teamName, round = REGULAR_ROUND_KEY) => {
    const teamTotalStats = round === REGULAR_ROUND_KEY ? teamTotalStatsRegular : teamTotalStatsRR 
    const newStats = JSON.parse(JSON.stringify(stats))
    let avg = (teamTotalStats.kicking[teamName] && teamTotalStats.kicking[teamName].AVG) || '-'
    let slg = (teamTotalStats.kicking[teamName] && teamTotalStats.kicking[teamName].SLG) || '-'
    let fp = (teamTotalStats.fielding[teamName] && teamTotalStats.fielding[teamName].FP) || 0
    let ij = (teamTotalStats.pitching[teamName] && teamTotalStats.pitching[teamName].IP) || '-'
    
    newStats.avg.new = avg.toString().replace('0.', '.')
    newStats.slg.new = slg.toString().replace('0.', '.')
    
    newStats.fp.new = fp// (parseFloat(fp) * 100) ? (parseFloat(fp) * 100).toFixed(1) : '-'
    
    newStats.era.new = (teamTotalStats.pitching[teamName] && teamTotalStats.pitching[teamName].ERA) || '-'
    newStats.k.new = (teamTotalStats.pitching[teamName] && teamTotalStats.pitching[teamName].K) || '-'
    newStats.one23.new = (teamTotalStats.pitching[teamName] && teamTotalStats.pitching[teamName]["123"]) || '-'

    newStats.ij.new = parseInt(ij) ? parseInt(ij) : '-'
    newStats.ab.new = (teamTotalStats.kicking[teamName] && teamTotalStats.kicking[teamName].AB) || '-'
    
    for (const s in newStats) {
        newStats[s].title = newStats[s].title.replace('', '')
    }

    return newStats
}