import React,  {useState} from "react";
import teams from 'data/leagueData/teamsFullInfo.json'
import teamsRR from 'data/leagueData/teamsFullInfoRR.json'

import RankTable from "components/Tables/RankTable.js";
import HeaderStats from "components/Stats/HeaderStats.js";
import { getLeagueStats } from "data/statCardsUtils.js";
import GenericSetup from "components/Setups/GenericSetup";
import { ROUNDS, ROUND_ROBIN_KEY, REGULAR_ROUND_KEY } from 'leagueConstants'
// import { getTeamPoints } from 'data/dataUtils'

export default function RankPage() {
  const [currentRound, setCurrentRound] = useState(ROUND_ROBIN_KEY);
  const [currentStats, setCurrentStats] = useState(getLeagueStats(ROUND_ROBIN_KEY));
  const handleRoundChange = (round) => {
    setCurrentRound(round)
    setCurrentStats(getLeagueStats(round))
  }
  const rankMap = {
    [REGULAR_ROUND_KEY]: {
      title: 'Ronda Regular',
      teams: teams
    },
    [ROUND_ROBIN_KEY]: {
      title: 'Round Robin',
      teams: teamsRR
    }
  }
  
 // console.log(JSON.stringify(getTeamPoints()))
  return (
    <>
      <div>
        <HeaderStats stats={currentStats}/>
        <hr className="my-6 mx-auto border-main-medium w-3/4" />
        <div className="w-full px-4">
        <GenericSetup rounds={ROUNDS} 
              handleRoundChange={handleRoundChange}
              selectedRound={currentRound}
          />
        </div>
        <div className="flex flex-wrap">
          <div className="w-full mb-12 px-4">
            <RankTable mainTitle={rankMap[currentRound].title} teams={rankMap[currentRound].teams} />
          </div>
        </div>
      </div>
      
    </>
  );
}
