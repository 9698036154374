// Rounds

export const REGULAR_ROUND_KEY = "regular" 
export const ROUND_ROBIN_KEY = "robin" 
export const FINAL_ROUND_KEY = "final" 

export const ROUNDS = {
    [REGULAR_ROUND_KEY]: {k: "r",v: "Ronda Regular", num: 8},
    [ROUND_ROBIN_KEY]: {k: "rr",v: "Round Robin", num: 3},
    [FINAL_ROUND_KEY]: {k: "rf",v: "Ronda Final", num: 2}
}

// Game sections

export const OFFENSIVE_KEY = "kicking" 
export const DEFENSIVE_KEY = "fielding" 
export const PITCHING_KEY = "pitching" 

export const GAME_SECTIONS = {
    [OFFENSIVE_KEY]: {key: OFFENSIVE_KEY, name: 'pateo'},
    [DEFENSIVE_KEY]: {key: DEFENSIVE_KEY, name: 'fildeo'},
    [PITCHING_KEY]: {key: PITCHING_KEY, name: 'pitcheo'}
}

export const CHAMPIONS = {
    [OFFENSIVE_KEY]: [
        {key: 'AVG', name: 'pateadora'},
        {key: 'SLG', name: 'slugger'}, 
        {key: 'RBI', name: 'impulsadora'}],
    [DEFENSIVE_KEY]: [
        {key: 'IF', name: 'infielder'}, 
        {key: 'OF', name: 'outfielder'}, 
        {key: 'catcher', name: 'receptora'}, 
        {key: 'firstBase', name: 'primera'}],
    [PITCHING_KEY]: [{key: 'WPCT', name: 'lanzadora'}]
}

export const SECTION_TITLE = {
    [OFFENSIVE_KEY]: "Pateo",
    [DEFENSIVE_KEY]: "Fildeo",
    [PITCHING_KEY]: "Pitcheo"
}