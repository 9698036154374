import { getPlayerNameFromNumber } from 'data/playersUtils';
import { getTeamLogo } from 'leagueUtils';
import React from 'react';

export default function StatsPerGameModal({ shouldShow, onRequestClose, data }) {
    const statKeys = [{"key": "HTC", "name": "HTC"}, {"key": "BB", "name": "BB"}, {"key": "K", "name": "K"}, 
      {"key": "SH", "name": "SH"}, {"key": "ROE", "name": "ROE"}, {"key": "H1", "name": "H1"},
      {"key": "H2", "name": "H2"}, {"key": "H3", "name": "H3"}, {"key": "HR", "name": "HR"}, {"key": "QB", "name": "QB"}, 
      {"key": "123", "name": "123"}, {"key": "O", "name": "O"}, {"key": "A", "name": "A"}, {"key": "E", "name": "E"}, 
      {"key": "CP", "name": "CP"}, {"key": "CL", "name": "CL"}, {"key": "ERA", "name": "ERA"}, {"key": "WHIP", "name": "WHIP"}]
    return shouldShow ? (
      <div
        className="fixed flex items-center justify-center align-middle 
          h-full w-full bg-black-opacity overflow-auto top-0 left-0 right-0"
        onClick={onRequestClose}
      >
        <div
          className="w-4/5 h-3/4 p-4 bg-white rounded overflow-y-auto"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flex w-full">
            <button className="text-xl" onClick={onRequestClose}>
              X
            </button>
            <div className="flex w-full items-center justify-center align-middle">
                <div className="ml-3 font-semibold text-md lg:text-lg text-main-dark uppercase">
                  {data.homeClub} vs {data.visitor}
                </div>
            </div>
          </div>
          <hr className="my-6 mx-auto border-main-medium w-full" />
          <div>
              <div className="mini-grid-wrapper">
                  {
                    Array.from({ length: 8 }, (_, i) => i + 1).map(i => (
                      <div key={i} className="bg-main-past-opacity rounded-mini"
                          style={{gridRow: 1, gridColumn: (i + 2)}}>
                          <p className="text-center font-semibold text-md text-main-dark">
                              {i === 8 ? "T" : i}
                          </p>
                      </div>
                    ))
                  }
                  <div className="bg-main-past-opacity rounded-mini"
                      style={{gridRow: 2, gridColumn: "1/3"}}>
                      <p className="text-center font-semibold text-md text-main-dark uppercase">
                          { data.visitor.substr(0, 3)}
                      </p>
                  </div>
                  <div className="bg-main-past-opacity rounded-mini"
                      style={{gridRow: 3, gridColumn: "1/3"}}>
                      <p className="text-center font-semibold text-md text-main-dark uppercase">
                          { data.homeClub.substr(0, 3)}
                      </p>
                  </div>
                  {
                    Array.from({ length: 8 }, (_, i) => i + 1).map(i => (
                      <div key={i} className="bg-main rounded-mini"
                          style={{gridRow: 2, gridColumn: (i + 2)}}>
                          <p className="text-center text-sm text-main-dark">
                              {i === 8 ? data.stats.visitor.total : data.stats.visitor.scorePerInning[i]}
                          </p>
                      </div>
                    ))
                  }
                  {
                    Array.from({ length: 8 }, (_, i) => i + 1).map(i => (
                      <div key={i} className="bg-main rounded-mini"
                          style={{gridRow: 3, gridColumn: (i + 2)}}>
                          <p className="text-center text-sm text-main-dark">
                              {i === 8 ? data.stats.homeClub.total : data.stats.homeClub.scorePerInning[i]}
                          </p>
                      </div>
                    ))
                  }
                  
              </div>
          </div>
          <hr className="my-6 mx-auto border-main-medium w-full" />
          {(data.stats.winner && data.stats.loser) ? (
            <div>
              <div className="my-6">
                <div className="grid-wrapper" style={{gridAutoRows: "minmax(40px, auto)", gridGap: "1px"}}>
                    <div className="flex items-center justify-center bg-transparent rounded-mini mb-3"
                      style={{gridRow: 1, gridColumn: "1/5"}}>
                        <p className="font-bold text-md text-main-dark uppercase">Ganado</p>
                    </div>
                    <div  className="flex bg-transparent rounded-mini align-middle justify-center items-center mb-3"
                      style={{gridRow: 2, gridColumn: 1}}>
                      <img src={getTeamLogo(data.stats.winner.team).default} alt="..."
                        className="h-10 w-10 align-middle justify-center border-none max-w-150-px"/>
                    </div>
                    <div className="flex items-center justify-center bg-main rounded-mini mb-3"
                        style={{gridRow: 2 , gridColumn: "2/5"}}>
                          <p className="text-sm text-main-dark uppercase">
                            {getPlayerNameFromNumber(data.stats.winner.team, data.stats.winner.number, data.stats.winner.name)}</p>
                    </div>
                    <div className="flex items-center justify-center bg-transparent rounded-mini mb-3"
                      style={{gridRow: 3, gridColumn: "1/5"}}>
                        <p className="font-bold text-md text-main-dark uppercase">Perdido</p>
                    </div>
                    <div  className="flex bg-transparent rounded-mini align-middle justify-center items-center mb-3"
                      style={{gridRow: 4, gridColumn: 1}}>
                      <img src={getTeamLogo(data.stats.loser.team).default} alt="..."
                        className="h-10 w-10 align-middle justify-center border-none max-w-150-px"/>
                    </div>
                    <div className="flex items-center justify-center bg-main rounded-mini mb-3"
                        style={{gridRow: 4 , gridColumn: "2/5"}}>
                          <p className="text-sm text-main-dark uppercase">
                          {getPlayerNameFromNumber(data.stats.loser.team, data.stats.loser.number, data.stats.loser.name)}
                          </p>
                    </div>
                </div>
              </div>
              <hr className="my-6 mx-auto border-main-medium w-full" />
              </div>) : null}
          <div className="my-6">
            <div className="grid-wrapper" style={{gridAutoRows: "minmax(40px, auto)", gridGap: "1px"}}>
                <div className="flex bg-transparent rounded-mini align-middle justify-center items-center mb-3"
                  style={{gridRow: 1, gridColumn: 1}}>
                  <img src={getTeamLogo(data.homeClub).default} alt="..."
                    className="h-10 w-10  border-none max-w-150-px"/>
                </div>
                <div className="flex items-center justify-center bg-transparent rounded-mini mb-3"
                  style={{gridRow: 1, gridColumn: "2/4"}}>
                    <p className="font-bold text-md text-main-dark uppercase">estadísticas</p>
                </div>
                <div  className="flex bg-transparent rounded-mini align-middle justify-center items-center mb-3"
                  style={{gridRow: 1, gridColumn: 4}}>
                  <img src={getTeamLogo(data.visitor).default} alt="..."
                    className="h-10 w-10 align-middle justify-center border-none max-w-150-px"/>
                </div>
                {statKeys.map((s, i) => (
                  <div key={s.key + i} className="flex items-center justify-center bg-main rounded-mini"
                    style={{gridRow: (i + 2) , gridColumn: "1"}}>
                      <p className="text-sm text-main-dark uppercase">{data.stats.homeClub.stats[s.key]}</p>
                  </div>
                ))}
                {statKeys.map((s, i) => (
                  <div key={s.key + i} className="flex items-center justify-center bg-main-past-opacity rounded-mini"
                    style={{gridRow: (i + 2) , gridColumn: "2/4"}}>
                      <p className="font-semibold text-md text-main-dark uppercase">{s.name}</p>
                  </div>
                ))}
                {statKeys.map((s, i) => (
                  <div key={s.key + i} className="flex items-center justify-center bg-main rounded-mini"
                    style={{gridRow: (i + 2) , gridColumn: "4"}}>
                      <p className="text-sm text-main-dark uppercase">{data.stats.visitor.stats[s.key]}</p>
                  </div>
                ))}
            </div>
          </div>          
        </div>
      </div>
    ) : null;
}
