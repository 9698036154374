import React from "react";

export default function RosterFooter({
  link,
  username
}) {
  return (
    <>
      <div className="relative w-full overflow-hidden -mt-20 h-20">
          <hr className="my-6 mx-auto border-main-medium w-3/4" />
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-main-dark font-semibold py-1">
              <a
                href={link}
                className="text-main-dark hover:text-main-light flex items-center justify-center text-lg"
              > 
                <i className="fab fa-instagram" />
                <span className="inline-block ml-2">{username}</span>
              </a>
            </div>
        </div>
      </div>
    </>
  );
}
