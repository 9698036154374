import { getTeamLogo } from "leagueUtils";
import React from "react";

export default function PrevWinnersTable() {
  return (
    <>
      <div className="w-full pt-8 px-4">
            <div className="grid-wrapper" style={{gridAutoRows: "minmax(25px, auto)"}}>
                <div className=" bg-main rounded-mini"
                    style={{gridRow: 1, gridColumn: 2}}>
                    <p className="font-semibold text-md text-main-dark text-center">
                        1
                    </p>
                </div>
                <div className=" bg-main rounded-mini"
                    style={{gridRow: 1, gridColumn: 3}}>
                    <p className="font-semibold text-md text-main-dark text-center">
                        2
                    </p>
                </div>
                <div className=" bg-main rounded-mini"
                    style={{gridRow: 1, gridColumn: 4}}>
                    <p className="font-semibold text-md text-main-dark text-center">
                        3
                    </p>
                </div>
                <div className=" bg-main rounded-mini flex items-center justify-center"
                style={{gridRow: 2, gridColumn: 1}}>
                <p className="font-semibold text-md text-main-dark text-center">
                    2018
                </p>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 2, gridColumn: 2}}>
                    <img src={getTeamLogo("Espartanas").default} className="h-8 w-8 my-1" alt="..."></img>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 2, gridColumn: 3}}>
                    <p className="text-md text-main-dark text-center">
                        <img src={getTeamLogo("Panteras").default} className="h-8 w-8 my-1" alt="..."></img>
                    </p>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 2, gridColumn: 4}}>
                    <p className="text-md text-main-dark text-center">
                        -
                    </p>
                </div>
                <div className=" bg-main rounded-mini flex items-center justify-center"
                    style={{gridRow: 3, gridColumn: 1}}>
                    <p className="font-semibold text-md text-main-dark text-center">
                        2021
                    </p>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 3, gridColumn: 2}}>
                    <img src={getTeamLogo("Espartanas").default} className="h-8 w-8 my-1" alt="..."></img>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 3, gridColumn: 3}}>
                    <p className="text-md text-main-dark text-center">
                        <img src={getTeamLogo("Vikingas").default} className="h-8 w-8 my-1" alt="..."></img>
                    </p>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 3, gridColumn: 4}}>
                    <p className="text-md text-main-dark text-center">
                        <img src={getTeamLogo("Panteras").default} className="h-8 w-8 my-1" alt="..."></img>
                    </p>
                </div>
                <div className=" bg-main rounded-mini flex items-center justify-center"
                    style={{gridRow: 4, gridColumn: 1}}>
                    <p className="font-semibold text-md text-main-dark text-center">
                        2022
                    </p>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 4, gridColumn: 2}}>
                    <p className="text-md text-main-dark text-center">
                        <img src={getTeamLogo("Panteras").default} className="h-8 w-8 my-1" alt="..."></img>
                    </p>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 4, gridColumn: 3}}>
                    <p className="text-md text-main-dark text-center">
                        <img src={getTeamLogo("Vikingas").default} className="h-8 w-8 my-1" alt="..."></img>
                    </p>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 4, gridColumn: 4}}>
                    <p className="text-md text-main-dark text-center">
                    <img src={getTeamLogo("Las Panas").default} className="h-8 w-8 my-1" alt="..."></img>
                    </p>
                </div>
                <div className=" bg-main rounded-mini flex items-center justify-center"
                    style={{gridRow: 5, gridColumn: 1}}>
                    <p className="font-semibold text-md text-main-dark text-center">
                        2023
                    </p>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 5, gridColumn: 2}}>
                    <p className="text-md text-main-dark text-center">
                        <img src={getTeamLogo("Panteras").default} className="h-8 w-8 my-1" alt="..."></img>
                    </p>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 5, gridColumn: 3}}>
                    <p className="text-md text-main-dark text-center">
                        <img src={getTeamLogo("Vikingas").default} className="h-8 w-8 my-1" alt="..."></img>
                    </p>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 5, gridColumn: 4}}>
                    <img src={getTeamLogo("Espartanas").default} className="h-8 w-8 my-1" alt="..."></img>
                </div>
                <div className=" bg-main rounded-mini flex items-center justify-center"
                    style={{gridRow: 6, gridColumn: 1}}>
                    <p className="font-semibold text-md text-main-dark text-center">
                        2024
                    </p>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 6, gridColumn: 2}}>
                    <p className="text-md text-main-dark text-center">
                        <img src={getTeamLogo("Panteras").default} className="h-8 w-8 my-1" alt="..."></img>
                    </p>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 6, gridColumn: 3}}>
                    <p className="text-md text-main-dark text-center">
                        <img src={getTeamLogo("Vikingas").default} className="h-8 w-8 my-1" alt="..."></img>
                    </p>
                </div>
                <div className=" bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 6, gridColumn: 4}}>
                    <img src={getTeamLogo("Espartanas").default} className="h-8 w-8 my-1" alt="..."></img>
                </div>
            </div>
            
      </div>
    </>
  );
}