import statsKicking from 'data/stats/statsKicking.json'
import statsPitching from 'data/stats/statsPitching.json'
import statsFielding from 'data/stats/statsFielding.json'
import teamStatsRegular from 'data/stats/teamStats.json'
import teamStatsRR from 'data/stats/teamStatsRR.json'
import statsPerGame from 'data/stats/statsPerGame.json'
import { OFFENSIVE_KEY, DEFENSIVE_KEY, PITCHING_KEY } from 'leagueConstants'
import { getPlayerNameFromNumber } from './playersUtils'
import { REGULAR_ROUND_KEY } from 'leagueConstants'

const keyMap = {
    validForChampion: {
        IF: "isValidForChampions_IF",
        OF: "isValidForChampions_OF",
        catcher: "isValidForChampions_2",
        firstBase: "isValidForChampions_3"
    },
    percentageOfParticipation: {
        IF: "perOfParticipation_IF",
        OF: "perOfParticipation_OF",
        catcher: "perOfParticipation_2",
        firstBase: "perOfParticipation_3"
    },
    FP: {
        IF: "FP_IF",
        OF: "FP_OF",
        catcher: "FP2",
        firstBase: "FP3"
    },
    PO: {
        IF: "PO_IF",
        OF: "PO_OF",
        catcher: "PO2",
        firstBase: "PO3"
    },
    A: {
        IF: "A_IF",
        OF: "A_OF",
        catcher: "A2",
        firstBase: "A3"
    },
    AP: {
        IF: "AP_IF",
        OF: "AP_OF",
        catcher: "AP2",
        firstBase: "AP3"
    },
    E: {
        IF: "E_IF",
        OF: "E_OF",
        catcher: "E2",
        firstBase: "E3"
    }
}

const getChampionFielder = (position, team, round = REGULAR_ROUND_KEY) => {
    const newStats = JSON.parse(JSON.stringify(statsFielding[round]))
        .filter(s => ((team ? s.team === team : true)) && s[keyMap.percentageOfParticipation[position]] > 0)
        .map(s => {
            s.sum = s[keyMap.PO[position]] + s[keyMap.A[position]]
            s.pa = s[keyMap.AP[position]]
            s[keyMap.FP[position]] = s[keyMap.FP[position]] !== null ? s[keyMap.FP[position]] : 0
            return s
        }).sort((a, b) => {
            if (a[keyMap.FP[position]] < b[keyMap.FP[position]]) return 1
            if (a[keyMap.FP[position]] > b[keyMap.FP[position]]) return -1
            else return sortBySum(a, b)
        }).map(s => mapFielding(s, position))
    return !team ? newStats.filter(s => s.isValidForChampions) : newStats
}

const getChampionKicker = (key, team, round = REGULAR_ROUND_KEY) => {
    const newStats = JSON.parse(JSON.stringify(statsKicking[round]))
    .filter(s => (team ? s.team === team : true))
    .sort((a, b) => {
        if (a[key] < b[key]) return 1
        if (a[key] > b[key]) return -1
        else return sortByPlateAppearance(a, b)
    }).map(s => mapKicking(s))
    
    return !team ? newStats.filter(s => s.isValidForChampions) : newStats
    
}

const getChampionPitcher = (key, team, round = REGULAR_ROUND_KEY) => {
    const newStats = JSON.parse(JSON.stringify(statsPitching[round]))  
        .filter(s => (team ? s.team === team : true))
        .sort((a, b) => {
            if (a.winningPercentage < b.winningPercentage) return 1
            if (a.winningPercentage > b.winningPercentage) return -1
            else return sortByERA(a, b)
        }).map(s => mapPitching(s))

    return !team ? newStats.filter(s => s.isValidForChampions && s.WPCT > 0) : newStats
}

const mapKicking = (s) => {
    return {
        name: s.Name,
        number: s["#"],
        team: s.team,
        H: s.H,
        BB: s.BB,
        K: s.SO,
        SH: s.SAC,
        H1: s["1B"],
        H2: s["2B"],
        H3: s["3B"],
        HR: s.HR,
        R: s.R,
        RBI: s.RBI,
        AVG: (s.AVG).toFixed(3).toString().replace('0.', '.'),
        SLG: (s.SLG).toFixed(3).toString().replace('0.', '.'),
        OBP: (s.OBP).toFixed(3).toString().replace('0.', '.'),
        ROE: s.ROE,
        OBPE: (s.OBPE).toFixed(3).toString().replace('0.', '.'),
        PA: s.PA,
        AB: s.AB,
        isValidForChampions: s.isValidForChampions
    }
}

const mapFielding = (s, position) => {
    return {
        name: s.Name,
        number: s["#"],
        team: s.team,
        FP: s[keyMap.FP[position]] < 100 ? (s[keyMap.FP[position]]).toFixed(2) : (s[keyMap.FP[position]]).toFixed(0),
        PO: s[keyMap.PO[position]],
        A: s[keyMap.A[position]],
        AP: s[keyMap.AP[position]],
        E: s[keyMap.E[position]],
        isValidForChampions: s[keyMap.validForChampion[position]]
    }
}

const mapPitching = (s) => {
    return {
        name: s.Name,
        number: s["#"],
        team: s.team,
        IP: s.IP,
        W: s.W,
        L: s.L,
        E: s.SV,
        BF: s.BF,
        H: s.H,
        ER: s.ER,
        R: s.R,
        K: s.K,
        BB: s.BB,
        SH: s.SAC,
        WPCT: s.winningPercentage ? s.winningPercentage.toFixed(2) : 0,
        ERA: s.ERA,
        WHIP: s.WHIP,
        isValidForChampions: s.isValidForChampions
    }
}

const categoriesPerSection = {
    [DEFENSIVE_KEY]: {
        categories: {
            'IF': { category: "Infielder" },
            'OF': { category: "Outfielder" },
            'catcher': { category: "Receptora" },
            'firstBase': { category: "Primera" }
        },
        method: getChampionFielder,
        mapper: mapFielding
    },
    [OFFENSIVE_KEY]: {
        categories: {
            'AVG': { category: "Pateadora" },
            'SLG': { category: "Slugger" },
            'RBI': { category: "Impulsadora" }
        },
        method: getChampionKicker,
        mapper: mapKicking
    },
    [PITCHING_KEY]: {
        categories: {
            'WPCT': { category: "Lanzadora" }
        },
        method: getChampionPitcher,
        mapper: mapPitching
    }
}

export const getLeaders = (category, team, round = REGULAR_ROUND_KEY) => {
    const stats = categoriesPerSection[category].categories
    const method = categoriesPerSection[category].method
    for (const s in stats) {
      const list = method(s, team, round).filter(s => s.isValidForChampions)

      stats[s].team = list && list[0] && list[0].team
      stats[s].number = list && list[0] && list[0].number
      stats[s].prevName = list && list[0] && list[0].name
      stats[s].name = list && list[0] && list[0].name && getPlayerNameFromNumber(stats[s].team, stats[s].number, stats[s].prevName)
    }

    return stats
}

export const getStatsDataForPage = (section, champion, shouldSlice, team, round = REGULAR_ROUND_KEY) => {
    const method = categoriesPerSection[section].method
    const mapper = categoriesPerSection[section].mapper
    let stats = method(champion, team, round).map(p => {
        p.champion = p.isValidForChampions ? 'SI' : 'NO'
        p.prevName = p.name
        p.name = getPlayerNameFromNumber(p.team, p.number, p.prevName)
        
        return p
    })

    if (team) {
        const statsForTeam = getStatsForTeam(mapper, team, section, champion, round)
        if (statsForTeam) stats.push(statsForTeam)
    }

    return shouldSlice ? stats.slice(0, 10) : stats
}

export const getStatsPerGame = (homeClub, visitor, round, day) => {
    const key = `${homeClub}-${visitor}`
    return statsPerGame[round][day].find(g => g.game === key)
}

const getStatsForTeam = (mapper, team, section, champion, round = REGULAR_ROUND_KEY) => {
    const teamStats = round === REGULAR_ROUND_KEY ? teamStatsRegular : teamStatsRR
    if (!teamStats[section][team]) return
    const stats = mapper(teamStats[section][team], champion)
    stats.champion = '-'
    return stats
}

const sortBySum = (a, b) => {
    if (a.sum < b.sum) return 1
    if (a.sum > b.sum) return -1
    else return sortByPA(a, b)
}

const sortByPA = (a, b) => {
    if (a.pa < b.pa) return 1
    if (a.pa > b.pa) return -1
    else return 0
}

const sortByPlateAppearance = (a, b) => {
    if (a.PA < b.PA) return 1
    if (a.PA > b.PA) return -1
    else return 0
}

const sortByWHIP = (a, b) => {
    if (a.WHIP > b.WHIP) return 1
    if (a.WHIP < b.WHIP) return -1
    else return 0
}

const sortByERA = (a, b) => {
    if (a.ERA > b.ERA) return 1
    if (a.ERA < b.ERA) return -1
    else return sortByWHIP(a, b)
}