import React from "react";
import { getTeamLogo } from "leagueUtils";

export default function PrevWinnersPerTeamTable() {
  return (
    <>
      <div className="w-full pt-8 px-4">
            <div className="grid-wrapper" style={{gridAutoRows: "minmax(25px, auto)"}}>
                <div className="bg-main rounded-mini"
                    style={{gridRow: 1, gridColumn: 2}}>
                    <p className="font-semibold text-md text-main-dark text-center">
                        1
                    </p>
                </div>
                <div className="bg-main rounded-mini"
                    style={{gridRow: 1, gridColumn: 3}}>
                    <p className="font-semibold text-md text-main-dark text-center">
                        2
                    </p>
                </div>
                <div className="bg-main rounded-mini"
                    style={{gridRow: 1, gridColumn: 4}}>
                    <p className="font-semibold text-md text-main-dark text-center">
                        3
                    </p>
                </div>
                <div className="px-2 bg-main rounded-mini min-w-140-px"style={{gridRow: 2, gridColumn: 1}}>
                    <div className="py-1 flex flex-wrap items-center">
                        <img src={getTeamLogo("Panteras").default} className="h-8 w-8 mr-2" alt="..."></img>
                        <p className="font-semibold text-md text-main-dark text-center">
                            Panteras
                        </p>
                    </div>
                </div>
                <div className="bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 2, gridColumn: 2}}>
                    <p className="text-md text-main-dark text-center">
                        3
                    </p>
                </div>
                <div className="bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 2, gridColumn: 3}}>
                    <p className="text-md text-main-dark text-center">
                        1
                    </p>
                </div>
                <div className="bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 2, gridColumn: 4}}>
                    <p className="text-md text-main-dark text-center">
                        1
                    </p>
                </div>
                <div className="pl-2 bg-main rounded-mini min-w-140-px"
                    style={{gridRow: 3, gridColumn: 1}}>
                    <div className="py-1 flex flex-wrap items-center">
                        <img src={getTeamLogo("Espartanas").default} className="h-8 w-8 mr-2" alt="..."></img>
                        <p className="font-semibold text-md text-main-dark text-center">
                            Espartanas
                        </p>
                    </div>
                </div>
                <div className="bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 3, gridColumn: 2}}>
                    <p className="text-md text-main-dark text-center"> 2</p>
                </div>
                <div className="bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 3, gridColumn: 3}}>
                    <p className="text-md text-main-dark text-center">
                        0
                    </p>
                </div>
                <div className="bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 3, gridColumn: 4}}>
                    <p className="text-md text-main-dark text-center">
                        2
                    </p>
                </div>
                <div className="pl-2 bg-main rounded-mini min-w-140-px"
                    style={{gridRow: 4, gridColumn: 1}}>
                    <div className="py-1 flex flex-wrap items-center">
                        <img src={getTeamLogo("Vikingas").default} className="h-8 w-8 mr-2" alt="..."></img>
                        <p className="font-semibold text-md text-main-dark text-center">
                            Vikingas
                        </p>
                    </div>
                </div>
                <div className="bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 4, gridColumn: 2}}>
                    <p className="text-md text-main-dark text-center">
                        0
                    </p>
                </div>
                <div className="bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 4, gridColumn: 3}}>
                    <p className="text-md text-main-dark text-center">
                        4
                    </p>
                </div>
                <div className="bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 4, gridColumn: 4}}>
                    <p className="text-md text-main-dark text-center">
                        0
                    </p>
                </div>
                <div className="pl-2 bg-main rounded-mini min-w-140-px"
                    style={{gridRow: 5, gridColumn: 1}}>
                    <div className="py-1 flex flex-wrap items-center">
                        <img src={getTeamLogo("Las Panas").default} className="h-8 w-8 mr-2" alt="..."></img>
                        <p className="font-semibold text-md text-main-dark text-center">
                            Las Panas
                        </p>
                    </div>
                </div>
                <div className="bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 5, gridColumn: 2}}>
                    <p className="text-md text-main-dark text-center">
                        0
                    </p>
                </div>
                <div className="bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 5, gridColumn: 3}}>
                    <p className="text-md text-main-dark text-center">
                        0
                    </p>
                </div>
                <div className="bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 5, gridColumn: 4}}>
                    <p className="text-md text-main-dark text-center">
                        1
                    </p>
                </div>
            </div>
            
      </div>
    </>
  );
}