import React from "react";

import { getTeamLogo } from 'leagueUtils'

export default function ChampionTable({
    headerTitles,
    stats,
    mainTitle,
    addLogo
}) {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
        <div className="rounded-t mb-0 py-4 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-main-dark-opacity uppercase">{ mainTitle }</h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full mb-mini">
            <thead>
              <tr>
              <th className="min-w-30px px-mini py-3 align-middle text-sm uppercase whitespace-nowrap font-semibold text-center rounded-mini mr-mini"></th>
              <th className="min-w-50 px-6 py-3 align-middle text-sm uppercase whitespace-nowrap font-semibold text-center rounded-mini mr-mini"></th>
                {headerTitles.map(header => (
                <th key={header.key} 
                  className="px-2 py-3 align-middle text-sm uppercase whitespace-nowrap font-semibold text-center bg-main text-main-dark rounded-mini">
                    {header.name}
                </th>
              ))}</tr>
            </thead>
            <tbody>
              {stats && stats.map((stat, i) => (
                  <tr key={stat.name}>
                    <td className="px-mini py-3 align-middle text-md whitespace-nowrap text-center items-center bg-main rounded-mini mr-mini">
                      <span className="font-bold text-main-dark text-center">{stat.name === "Total" ? "-" : (i + 1)}</span>
                    </td>
                    <td className="flex px-6 py-3 align-middle text-md whitespace-nowrap text-center items-center bg-main rounded-mini mr-mini">
                      {addLogo && <img src={getTeamLogo(stat.team).default} className="h-12 w-12" alt="..."></img>}
                      <span className="ml-3 font-bold text-main-dark text-center">{stat.name}</span>
                    </td>
                    {headerTitles.map(header => (
                        <td key={(stat.name + '-' + header.key)} className="px-2 py-3 align-middle text-md text-center whitespace-nowrap bg-main-opacity rounded-mini">
                          {stat[header.key]}
                        </td>
                      ))
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
