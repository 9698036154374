import aguilasPlayers from 'data/teamsData/players/aguilas.json'
import espartanasPlayers from 'data/teamsData/players/espartanas.json'
import gladiadorasPlayers from 'data/teamsData/players/gladiadoras.json'
import goodiesPlayers from 'data/teamsData/players/goodies.json'
import vikingasPlayers from 'data/teamsData/players/vikingas.json'
import murallasPlayers from 'data/teamsData/players/murallas.json'
import tropicalesPlayers from 'data/teamsData/players/tropicales.json'
import laspanasPlayers from 'data/teamsData/players/laspanas.json'
import panterasPlayers from 'data/teamsData/players/panteras.json'
import coaches from 'data/teamsData/coaches.json'

const teamMap = {
    "Espartanas": espartanasPlayers,
    "Panteras": panterasPlayers,
    "Vikingas": vikingasPlayers,
    "Tropicales": tropicalesPlayers,
    "Gladiadoras": gladiadorasPlayers,
    "Goodies": goodiesPlayers,
    "Murallas": murallasPlayers,
    "Las Panas": laspanasPlayers,
    "Aguilas": aguilasPlayers
}

export const getTeamPlayers = (teamName) => {
    return teamMap[teamName]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(p => {
            p.name = capitalize(p.name)
            return p
        })
}

export const getTeamCoaches = (teamName) => {
    return coaches[teamName]
        .map(p => {
            p.name = capitalize(p.name)
            return p
        })
}

export const getPlayerNameFromNumber = (team, number, prevName) => {
    if (team === "Goodies") return capitalize(prevName)
    const p = teamMap[team].find(p => parseInt(p.shirt) === parseInt(number))
    return capitalize(((p && p.name) || prevName))
}

const capitalize = (fullName, char = " ") => {
    return fullName.split(char)
        .map(name => {
            const firstLetter = (name[0]).toUpperCase()
            return firstLetter + (name.slice(1)).toLowerCase()
        }).join(char)

}