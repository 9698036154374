import React from "react";

export default function CardStats({
  statName,
  statValue,
  statIconName,
  statIconColor,
  statDescription
}) {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-main-opacity rounded mb-6 xl:mb-0 shadow-lg">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5 className="text-main-medium-light uppercase font-bold text-xs">
                {statName}
              </h5>
              <span className="font-semibold text-md text-main-dark">
                {statValue}
              </span>
            </div>
            <div className="relative w-auto pl-2 flex-initial">
              <div
                className={
                  "text-white p-3 text-center inline-flex items-center justify-center w-8 h-8 lg:w-12 lg:h-12 shadow-lg rounded-full " +
                  statIconColor
                }
              >
                <i className={statIconName}></i>
              </div>
            </div>
          </div>
          {statDescription && (<p className="text-md text-blueGray-400 mt-4">
            <span className="whitespace-nowrap">{statDescription}</span>
          </p>)}
        </div>
      </div>
    </>
  );
}