import React from 'react';

import { getSelectorClass } from 'components/Setups/setupUtils'

export default function ChampionSetup({
    sections,
    handleSectionChange,
    selectedSection,
    champions,
    handleChampionChange,
    selectedChampion,
    team
}) {
  return (
    <>
        <div className="relative pb-4 pt-1">
            <div>
                <ul className="flex pl-0 pb-4 flex-wrap">
                    {Object.keys(sections).map((s) => (
                        <li key={s} className="pr-1 pb-2">
                            <a href={team ? ("#" +  (team).replace(' ', '')) : "#section"} onClick={() => handleSectionChange(s)} 
                                className={getSelectorClass(s, selectedSection)}>
                                {sections[s].name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <ul className="flex pl-0 rounded list-none flex-wrap">
                    {champions.map((champ) => (
                        <li key={champ.key} className="pr-1">
                            <a href={team ? ("#" +  (team).replace(' ', '')) : "#champion"} onClick={() => handleChampionChange(champ.key)} 
                                className={getSelectorClass(champ.key, selectedChampion)}>
                                {champ.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </>
  );
}
