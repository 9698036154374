import React, { useState } from 'react';

import ChampionSetup from "components/Setups/ChampionSetup";
import KickingStats from "components/Stats/KickingStats.js";
import FieldingStats from "components/Stats/FieldingStats.js";
import PitchingStats from "components/Stats/PitchingStats.js";
import ChampionTable from "components/Tables/ChampionTable.js";
import GenericSetup from 'components/Setups/GenericSetup';

import { GAME_SECTIONS, OFFENSIVE_KEY, CHAMPIONS, DEFENSIVE_KEY, PITCHING_KEY, SECTION_TITLE, ROUNDS, ROUND_ROBIN_KEY, REGULAR_ROUND_KEY } from 'leagueConstants'
import { getFirstChampionOfSection, getHeaderForStatsTable, getChampionName } from 'leagueUtils'
import { getStatsDataForPage } from "data/statsUtils"


export default function ChampionPage() {
  const defaultSection = OFFENSIVE_KEY
  const defaultChampion = getFirstChampionOfSection(defaultSection)
  const [currentSection, setCurrentSection] = useState(defaultSection);
  const [currentChampion, setCurrentChampion] = useState(defaultChampion);
  const [currentHeader, setCurrentHeader] = useState(getHeaderForStatsTable(defaultSection, defaultChampion));
  const [currentStats, setCurrentStats] = useState(getStatsDataForPage(defaultSection, defaultChampion, null, null, ROUND_ROBIN_KEY));
  const [currentRound, setCurrentRound] = useState(ROUND_ROBIN_KEY);
  const handleRoundChange = (round) => {
    setCurrentRound(round)
    setCurrentSection(defaultSection)
    setCurrentChampion(defaultChampion)
    setCurrentHeader(getHeaderForStatsTable(defaultSection, defaultChampion))
    setCurrentStats(getStatsDataForPage(defaultSection, defaultChampion, null, null, round))
  }
  
  const handleSectionChange = (section) => {
    const firstChampionOfSection = getFirstChampionOfSection(section)
    setCurrentSection(section)
    setCurrentChampion(firstChampionOfSection)
    setCurrentHeader(getHeaderForStatsTable(section, firstChampionOfSection))
    setCurrentStats(getStatsDataForPage(section, firstChampionOfSection, null, null, currentRound))
  }

  const handleChampionChange = (champ) => {
    setCurrentChampion(champ)
    setCurrentHeader(getHeaderForStatsTable(currentSection, champ))
    setCurrentStats(getStatsDataForPage(currentSection, champ, null, null, currentRound))
  }
  const titleMap = {
    [REGULAR_ROUND_KEY]: 'Ronda Regular',
    [ROUND_ROBIN_KEY]: 'Round Robin'
  }

  return (
    <>
      <div className="flex flex-wrap bg-cover pt-32 ">
        <div className="w-full mb-12 px-4">
          <div className="rounded-t mb-0 pb-4 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                <h3 className="font-bold text-lg text-main-dark-opacity uppercase">Líderes {SECTION_TITLE[currentSection]}</h3>
              </div>
            </div>
          </div>
          {currentSection === OFFENSIVE_KEY && <KickingStats style={{padding: "pt-0 pb-1"}} round={currentRound}/>}
          {currentSection === DEFENSIVE_KEY && <FieldingStats style={{padding: "pt-0 pb-1"}} round={currentRound}/>}
          {currentSection === PITCHING_KEY && <PitchingStats style={{padding: "pt-0 pb-1"}} round={currentRound}/>}
          <hr className="my-6 mx-auto border-main-medium w-3/4" />
          <GenericSetup rounds={ROUNDS} 
              handleRoundChange={handleRoundChange}
              selectedRound={currentRound}
          />
          <ChampionSetup sections={GAME_SECTIONS}
              handleSectionChange={handleSectionChange}
              selectedSection={currentSection}
              selectedChampion={currentChampion}
              champions={CHAMPIONS[currentSection]}
              handleChampionChange={handleChampionChange}
          />
          <ChampionTable mainTitle={titleMap[currentRound] + ' - ' + getChampionName(currentSection, currentChampion)} 
            stats={currentStats} 
            headerTitles={currentHeader} 
            addLogo={true} />
        </div>
      </div>
    </>
  );
}
