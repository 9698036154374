const baseSelectorClass = "first:ml-0 text-xs font-semibold flex w-auto h-8 mx-1 py-0 px-1" +
" rounded items-center justify-center leading-tight relative uppercase"

export const getSelectorClass = (value, selectedValue, defaultClass = baseSelectorClass, pastValues = []) => {
    if (value === selectedValue) 
        return defaultClass + " bg-main-opacity text-main-dark border-dark"
    else if (pastValues.includes(value.toString()))
        return defaultClass + " bg-main-past text-main-light "
    else 
    return defaultClass + " bg-main text-main-light"
}
