import React from "react";
import { Switch, Route } from "react-router-dom";

// components
import LeagueNavbar from "components/Navbars/LeagueNavbar.js";

// views
import RankPage from "views/league/RankPage.js";
import CalendarPage from "views/league/CalendarPage.js";
import ScorePage from "views/league/ScorePage.js";
import RosterPage from "views/league/RosterPage.js";
import ChampionPage from "views/league/ChampionPage.js";
import TeamStatPage from "views/league/TeamStatPage.js";
import ScrollToTop from "components/ScrollToTop";

export default function League() {
  return (
    <>
      <div >
        <div className="min-h-screen bg-cover bg-no-repeat"
            style={{
              backgroundImage: "url(" + require("assets/leagueImg/background.jpeg").default + ")",
              marginTop: "-56px"
            }}
        > 
          <ScrollToTop />
          <LeagueNavbar />
          <div className="px-4 py-16 md:px-10 mx-auto w-full -m-24">
            <Switch>
              <Route path="/league/rank" exact component={RankPage} />
              <Route path="/league/calendar" exact component={CalendarPage} />
              <Route path="/league/score" exact component={ScorePage} />
              <Route path="/league/roster" exact component={RosterPage} />
              <Route path="/league/stats/champions" exact component={ChampionPage} />
              <Route path="/league/stats/teams" exact component={TeamStatPage} />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
}
