import React from "react";
import ReactTooltip from 'react-tooltip'

import CardStats from "components/Cards/CardStats.js";

export default function HeaderStats({stats, team, style}) {
  const keyTitles = ['ij', 'ab', 'avg', 'slg', 'fp', 'one23', 'era', 'k']
  const description = team ? null : 'LKM 2024'
  const padding =  (style && style.padding) || "pt-32 pb-2"

  return (
    <>
      <div className={"relative " + padding}>
        <div className="rounded-t mb-0 pb-4 px-4 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h3 className="font-bold text-lg text-main-dark-opacity uppercase">Estadísticas { description }</h3>
            </div>
          </div>
        </div>
        <div className="w-full">
            <div className="flex flex-wrap">
              {
                keyTitles.map(k => (
                  <div key={k} className="w-1/2 lg:w-test px-4">
                    <div  data-tip={(stats[k].tooltip).toUpperCase()}>
                      <CardStats
                        statName={stats[k].title}
                        statValue={(stats[k].new).toString()}
                        statIconName={stats[k].icon}
                        statIconColor={stats[k].color}
                        statDescription={description}
                      />
                    </div>
                    <ReactTooltip place="top" 
                      type="light" 
                      effect="solid" 
                      arrowRadius="3"
                      textColor='#454238' backgroundColor='#faf8f2'/>
                  </div>
                ))
              }
            </div>
        </div>
      </div>
    </>
  );
}
