import React from "react";

import { getTeamLogo } from 'leagueUtils'

export default function CardChampion({
    name, 
    category, 
    team
}) {
  return (
    <>
      <div>
        <div className="relative flex flex-col min-w-0 break-words bg-main-opacity w-full mb-6 mt-8 shadow-lg rounded">
          <div className="px-1 md:px-6">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                <div className="relative">
                  <img
                    alt="..."
                    src={getTeamLogo(team).default}
                    className="h-16 w-16 align-middle justify-center border-none absolute -m-card  max-w-150-px"
                  />
                </div>
              </div>
            </div>
            <div className="text-center mt-12">
              <h3 className="text-sm md:text-lg font-semibold leading-normal mb-2 text-main-dark">
                {name}
              </h3>
              <div className="text-xs md:text-sm leading-normal mt-0 mb-2 text-main-light font-bold uppercase">
                {category}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
