import React, {useState} from 'react';

import { getTeamLogo, getTeamProperty } from 'leagueUtils'
import StatsPerGameModal from 'components/Modals/StatsPerGameModal'
import { getStatsPerGame } from 'data/statsUtils';

export default function CalendarTable({
  round,
  page,
  data
}) {
  const [showModal, setShowModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState({});
  const selectedCalendar = data && data[round] && data[round][page]
  const date = (selectedCalendar && selectedCalendar[0] && selectedCalendar[0].date) || '-'
  const place = (selectedCalendar && selectedCalendar[0] && selectedCalendar[0].place) || '-'
  const headerTitles = ['Homeclub', '-', 'Visitante']

  const enableModal = (game) => {
    const stats = getStatsPerGame(game.homeClub, game.visitor, round, page)
    if (game && game.isGameInPast && stats && !showModal) {
      setShowModal(true)
      setSelectedGame({...game, stats })
    }
  }

  return (
    <>
      <div className="flex">
      <div className="relative flex flex-col justify-center min-w-0 break-words w-full mb-6">
        <div className="rounded-t mb-0 py-4 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h3 className="font-bold text-lg text-main-dark-opacity uppercase">{date + " - " + place}</h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto bg-main-opacity rounded">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>{headerTitles.map(title => (
                <th key={title} 
                  className="px-6 align-middle py-3 text-sm uppercase border border-solid border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-100 text-main-dark">
                    {title}
                </th>
              ))}</tr>
            </thead>
            <tbody>
              {selectedCalendar && selectedCalendar.map(game => (
                  <tr key={game.homeClub + game.visitor} onClick={() => enableModal(game)}>
                    <th className="px-2 py-4 border-l-0 border-r-0 border-t-0 border-b-1 text-sm whitespace-nowrap text-center">
                      <div className="flex items-center justify-center w-full">
                        <div className="flex xs:w-full md:w-4/12 justify-end md:justify-center">
                          <span className="mr-2 font-bold text-main-dark">{getTeamProperty(game.homeClub, 'name') || game.homeClub}</span>
                          <img src={getTeamLogo(game.homeClub).default} className="h-8 w-8" alt="..."></img>
                        </div>
                      </div>
                    </th>
                    <td className="px-2 py-4 border-t-0 border-b-1 border-l-0 border-r-0 text-sm text-center whitespace-nowrap text-main-medium">{game.hour}</td>
                    <td className="px-2 py-4 border-l-0 border-r-0 border-t-0 border-b-1 text-sm whitespace-nowrap p-4 text-center ">
                      <div className="flex items-center justify-center w-full">
                        <div className="flex xs:w-full md:w-4/12 justify-start md:justify-center">
                          <img src={getTeamLogo(game.visitor).default} className="h-8 w-8" alt="..."></img>
                          <span className="ml-2 font-bold text-main-dark">{getTeamProperty(game.visitor, 'name')|| game.visitor}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
      <StatsPerGameModal
        shouldShow={showModal}
        onRequestClose={() => {
          setShowModal((prev) => !prev);
        }}
        data={selectedGame}
      >
      </StatsPerGameModal>
      </div>
    </>
  );
}
