import React from 'react';

import { getSelectorClass } from 'components/Setups/setupUtils'

export default function CalendarSetup({
    rounds,
    pages,
    handleRoundChange,
    handlePageChange,
    selectedRound,
    selectedPage,
    pastPages
}) {
    const roundNumberClass = "first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 py-0 px-1" + 
        " rounded items-center justify-center leading-tight relative uppercase"
  return (
    <>
        <div className="relative pb-10">
            <div>
                <ul className="flex pl-0 pb-4 flex-wrap">
                    {Object.keys(rounds).map((round) => (
                        <li key={round} className="pr-1">
                            <a href="#round" onClick={() => handleRoundChange(round)} 
                                className={getSelectorClass(round, selectedRound)}>
                                {rounds[round].v}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <ul className="flex pl-0 rounded list-none flex-wrap">
                    {pages.map((pageNumber) => (
                        <li key={pageNumber} className="pr-1">
                            <a href="#round" onClick={() => handlePageChange(pageNumber)} 
                                className={getSelectorClass(pageNumber, selectedPage, roundNumberClass, pastPages)}>
                                {pageNumber}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </>
  );
}
