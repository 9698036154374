import React, {useState, createRef, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import { createPopper } from "@popperjs/core";
import { processTeamKeyForPath, getTeamsOrderedByName } from "leagueUtils";

const IndexDropdown = () => {
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = createRef();
  const popoverDropdownRef = createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const location = useLocation();

  useEffect(() => {
    setDropdownPopoverShow(false)
  }, [location]);

  return (
    <>
      <a
        className="hover:text-main-light text-main-dark px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
        href="#stats"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <i className="text-main-dark fa fa-chart-line text-lg leading-lg mr-2" />{" "}
        Estadísticas
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <Link
          to="/league/stats/champions"
          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-main-light h-30-px"
        >
          Champions
        </Link>
        <div className="h-0 mx-4 my-2 border border-solid border-main-medium" />
        <span
          className={
            "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-main-dark"
          }
        >
          Equipos
        </span>
        {getTeamsOrderedByName().map((t) => (
          <Link
            key={t.key}
            to={"/league/stats/teams#" + processTeamKeyForPath(t.key)}
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-main-light h-30-px"
          >
            {t.name}
          </Link>
        ))}
      </div>
    </>
  );
};

export default IndexDropdown;
