/*eslint-disable*/
import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";

// components
import StatsDropdown from "components/Dropdowns/StatsDropdown.js";
import TeamsDropdown from "components/Dropdowns/TeamsDropdown.js";
import DocumentsDropdown from "components/Dropdowns/DocumentsDropdown.js";


export default function LeagueNavbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    setNavbarOpen(false)
  }, [location]);

  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-main shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-main-dark text-md font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            >
              Liga de Kickingball Madrid
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-main lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                <li className="flex items-center h-45-px">
                    <Link to="/league/calendar"
                        className="hover:text-main-light text-main-dark px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    >
                    <i className="text-main-dark fa fa-calendar text-lg leading-lg mr-2" />{" "}
                    Calendario
                    </Link>
                </li>
                <li className="flex items-center h-45-px">
                    <Link to="/league/rank"
                        className="hover:text-main-light text-main-dark px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    >
                    <i className="text-main-dark fa fa-ranking-star text-lg leading-lg mr-2" />{" "}
                    Clasificación
                    </Link>
                </li>
                <li className="flex items-center h-45-px">
                    <TeamsDropdown />
                </li>
                <li>
                  <StatsDropdown />
                </li>
                <li>
                  <DocumentsDropdown />
                </li>
                <li className="flex items-center h-45-px">
                    <a
                        className="hover:text-main-light text-main-dark px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                        href="https://www.instagram.com/kickingballmadrid?igsh=MzJlNDc0am9naHRo&utm_source=qr"
                        target="_blank"
                    >
                    <i className="text-main-dark fab fa-instagram text-lg leading-lg " />
                    <span className="lg:hidden inline-block ml-2">Instagram</span>
                    </a>
                </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
