import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom"

import ChampionSetup from "components/Setups/ChampionSetup";
import KickingStats from "components/Stats/KickingStats.js";
import FieldingStats from "components/Stats/FieldingStats.js";
import PitchingStats from "components/Stats/PitchingStats.js";
import ChampionTable from "components/Tables/ChampionTable.js";

import { GAME_SECTIONS, OFFENSIVE_KEY, CHAMPIONS, DEFENSIVE_KEY, PITCHING_KEY, SECTION_TITLE, REGULAR_ROUND_KEY, ROUNDS, ROUND_ROBIN_KEY } from 'leagueConstants'
import { getTeamFromLocation, getFirstChampionOfSection, getHeaderForStatsTable, getFirstTeam, isInRoundRobin } from 'leagueUtils'
import { getStatsDataForPage } from "data/statsUtils"
import GenericSetup from 'components/Setups/GenericSetup';

export default function TeamStatPage() {
  const defaultSection = OFFENSIVE_KEY
  const defaultChampion = getFirstChampionOfSection(defaultSection)
  const defaultTeam = getTeamFromLocation(useLocation()) || getFirstTeam()
  const [currentSection, setCurrentSection] = useState(defaultSection);
  const [currentChampion, setCurrentChampion] = useState(defaultChampion);
  const [currentRound, setCurrentRound] = useState(REGULAR_ROUND_KEY);
  const [isInRR, setIsInRR] = useState(isInRoundRobin(defaultTeam));
  const handleRoundChange = (round) => {
    setCurrentRound(round)
    setCurrentSection(defaultSection)
    setCurrentChampion(defaultChampion)
  }
  
  const handleSectionChange = (section) => {
    setCurrentSection(section)
    setCurrentChampion(getFirstChampionOfSection(section))
  }

  const handleChampionChange = (champ) => {
    setCurrentChampion(champ)
  }

  useEffect(() => {
    setIsInRR(isInRoundRobin(defaultTeam))
    setCurrentRound(isInRoundRobin(defaultTeam) ? ROUND_ROBIN_KEY : REGULAR_ROUND_KEY)
    window.scrollTo(0, 0);
  }, [defaultTeam]);

  return (
    <>
      <div className="flex flex-wrap bg-cover pt-32">
        <div className="w-full mb-12 px-4">
          <div className="rounded-t mb-0 pb-4 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                <h3 className="font-bold text-lg text-main-dark-opacity uppercase">Líderes {SECTION_TITLE[currentSection]}</h3>
              </div>
            </div>
          </div>
          {currentSection === OFFENSIVE_KEY && <KickingStats team={defaultTeam} style={{padding: "pt-0 pb-1"}} round={currentRound}/>}
          {currentSection === DEFENSIVE_KEY && <FieldingStats team={defaultTeam} style={{padding: "pt-0 pb-1"}} round={currentRound}/>}
          {currentSection === PITCHING_KEY && <PitchingStats team={defaultTeam} style={{padding: "pt-0 pb-1"}} round={currentRound}/>}
          <hr className="my-6 mx-auto border-main-medium w-3/4" />
          {isInRR && <GenericSetup rounds={ROUNDS} 
              handleRoundChange={handleRoundChange}
              selectedRound={currentRound}
              team={defaultTeam}
          />}
          <ChampionSetup sections={GAME_SECTIONS}
              handleSectionChange={handleSectionChange}
              selectedSection={currentSection}
              selectedChampion={currentChampion}
              champions={CHAMPIONS[currentSection]}
              handleChampionChange={handleChampionChange}
              team={defaultTeam}
          />
          <ChampionTable stats={getStatsDataForPage(currentSection, currentChampion, false, defaultTeam, currentRound)} headerTitles={getHeaderForStatsTable(currentSection, currentChampion, true)} />
        </div>
      </div>
    </>
  );
}
