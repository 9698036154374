import React from "react";
import {getTeamRecap} from 'leagueUtils'
import { REGULAR_ROUND_KEY, ROUND_ROBIN_KEY } from "leagueConstants";

export default function RecapPerTeam({team, round = REGULAR_ROUND_KEY}) {
    const recap = getTeamRecap(team, round)
    const hasRecap = !!recap.position
    const titleMap = {
        [REGULAR_ROUND_KEY]: "Resumen Ronda Regular",
        [ROUND_ROBIN_KEY]: "Resumen Round Robin"
    }
    return (
        <>
        {hasRecap && <div className="w-full pt-2 pb-6 px-4">
            <div className="rounded-t mb-0 pb-4 border-0">
            <div className="flex flex-wrap items-center">
                <div className="relative w-full max-w-full flex-grow flex-1">
                <h3 className="font-bold text-lg text-main-dark-opacity uppercase">{titleMap[round]}</h3>
                </div>
            </div>
            </div>
            <div className="grid-wrapper">
                <div className="pl-2 bg-main-opacity rounded-mini"
                    style={{gridRow: 1, gridColumn: "1 / 3"}}>
                    <p className="text-left font-semibold text-md text-main-dark">
                        Jugados
                    </p>
                    <span className="text-main-medium-light uppercase font-bold text-sm">
                        {recap.JJ}
                    </span>
                </div>
                <div className="pl-2 bg-main-opacity rounded-mini"
                    style={{gridRow: 1, gridColumn: "3 / 5"}}>
                    <p className="text-left font-semibold text-md text-main-dark">
                        Pendientes
                    </p>
                    <span className="text-main-medium-light uppercase font-bold text-sm">
                    {recap.pp}
                    </span>
                </div>
                <div className="pl-2 bg-main-opacity rounded-mini"
                    style={{gridRow: 2, gridColumn: "1"}}>
                    <p className="text-left font-semibold text-md text-main-dark">
                        G
                    </p>
                    <span className="text-main-medium-light uppercase font-bold text-sm">
                        {recap.G}
                    </span>
                </div>
                <div className="pl-2 bg-main-opacity rounded-mini"
                    style={{gridRow: 2, gridColumn: "2"}}>
                    <p className="text-left font-semibold text-md text-main-dark">
                        P
                    </p>
                    <span className="text-main-medium-light uppercase font-bold text-sm">
                    {recap.P}
                    </span>
                </div>
                <div className="pl-2 bg-main-opacity rounded-mini"
                    style={{gridRow: 2, gridColumn: "3"}}>
                    <p className="text-left font-semibold text-md text-main-dark">
                        E
                    </p>
                    <span className="text-main-medium-light uppercase font-bold text-sm">
                    {recap.E}
                    </span>
                </div>
                <div className="pl-2 bg-main-opacity rounded-mini"
                    style={{gridRow: 2, gridColumn: "4"}}>
                    <p className="text-left font-semibold text-md text-main-dark">
                        #
                    </p>
                    <span className="text-main-medium-light uppercase font-bold text-sm">
                    {recap.position}
                    </span>
                </div>
                <div className="pl-2 bg-main-opacity rounded-mini"
                    style={{gridRow: 3, gridColumn: "1"}}>
                    <p className="text-left font-semibold text-md text-main-dark">
                        PTOS
                    </p>
                    <span className="text-main-medium-light uppercase font-bold text-sm">
                    {recap.PTOS}
                    </span>
                </div>
                <div className="pl-2 bg-main-opacity rounded-mini"
                    style={{gridRow: 3, gridColumn: "2"}}>
                    <p className="text-left font-semibold text-md text-main-dark">
                        CF
                    </p>
                    <span className="text-main-medium-light uppercase font-bold text-sm">
                    {recap.CF}
                    </span>
                </div>
                <div className="pl-2 bg-main-opacity rounded-mini"
                    style={{gridRow: 3, gridColumn: "3"}}>
                    <p className="text-left font-semibold text-md text-main-dark">
                        CC
                    </p>
                    <span className="text-main-medium-light uppercase font-bold text-sm">
                    {recap.CC}
                    </span>
                </div>
                <div className="pl-2 bg-main-opacity rounded-mini"
                    style={{gridRow: 3, gridColumn: "4"}}>
                    <p className="text-left font-semibold text-md text-main-dark">
                        DC
                    </p>
                    <span className="text-main-medium-light uppercase font-bold text-sm">
                    {recap.DIFF}
                    </span>
                </div>
            </div>
            </div>}
        </>
    );
}