import React from "react";

import { getTeamLogo } from 'leagueUtils'

const keys = ['JJ', 'G', 'P', 'E', 'CF', 'CC', 'DIFF', 'PTOS']

export default function RankTable({
    mainTitle,
    teams
}) {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full">
        <div className="rounded-t mb-0 py-4 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h3 className="font-bold text-lg text-main-dark-opacity uppercase">Clasificación - { mainTitle }</h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full mb-mini">
            <thead>
              <tr>
                <th className="min-w-48 px-6 py-3 align-middle text-md uppercase whitespace-nowrap font-semibold text-center rounded-mini">
                </th>
                {keys.map(title => (
                    <th key={title} 
                      className="px-4 py-3 align-middle text-sm text-main-dark uppercase whitespace-nowrap font-semibold text-center bg-main rounded-mini mr-mini">
                      {title}
                    </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {teams && teams.map(team => (
                  <tr key={team.key}>
                    <th className="px-6 py-4 align-middle text-md whitespace-nowrap text-center flex items-center bg-main rounded-mini mr-mini">
                      <img src={getTeamLogo(team.key).default} className="h-12 w-12" alt="..."></img>
                      <span className="ml-3 font-bold text-main-dark">{team.name}</span>
                    </th>
                    {keys.map(k => (
                        <td key={(team.key + '-' + k)} className="px-4 py-4 align-middle text-md text-center whitespace-nowrap rounded-mini bg-main-opacity mr-mini">
                          {team[k]}
                        </td>
                      ))
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
