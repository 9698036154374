import React from "react";

import CardChampion from "components/Cards/CardChampion.js";
import { getLeaders } from "data/statsUtils"
import { OFFENSIVE_KEY, REGULAR_ROUND_KEY} from 'leagueConstants'

export default function KickingStats({team, style, round = REGULAR_ROUND_KEY}) {
  const stats = getLeaders(OFFENSIVE_KEY, team, round)
  const padding = (style && style.padding) || "pt-1 pb-10"
  return (
    <>
      <div className={"relative " + padding}>
        <div className="w-full">
            <div className="flex flex-wrap justify-center">
              {
                Object.keys(stats).map(k => (
                  <div key={k} className="w-1/3 md:w-3/12 px-mini md:px-1">
                    <CardChampion team={(team ? team : stats[k].team)} name={(stats[k].name || '-')} category={stats[k].category}/>
                  </div>
                ))
              }
            </div>
        </div>
      </div>
    </>
  );
}
