import React, {useState, useEffect} from 'react';

import RosterTable from "components/Tables/RosterTable";
import RosterFooter from "components/Footer/RosterFooter";
import HeaderStats from "components/Stats/HeaderStats.js";
import RecapPerTeam from "components/Tables/RecapPerTeam"
import GamesPerTeamTable from "components/Tables/GamesPerTeamTable"
import { getTeamStats } from "data/statCardsUtils.js";
import { getTeamPlayers, getTeamCoaches } from "data/playersUtils.js";
import { getTeamFromLocation, getTeamProperty, getFirstTeam, getTeamLogo } from 'leagueUtils'
import {
  useLocation
} from "react-router-dom"
import { ROUND_ROBIN_KEY, ROUNDS } from 'leagueConstants';
import GenericSetup from 'components/Setups/GenericSetup';
import { isInRoundRobin } from 'leagueUtils';
import { REGULAR_ROUND_KEY } from 'leagueConstants';

export default function RosterPage() {
  const defaultTeam = getTeamFromLocation(useLocation()) || getFirstTeam()
  const aspectRatio = getTeamProperty(defaultTeam, 'aspectRatio') 
  const style = {
    height: "150px",
    width: (150 * aspectRatio).toString + "px"
  }
  const [currentRound, setCurrentRound] = useState(isInRoundRobin(defaultTeam) ? ROUND_ROBIN_KEY : REGULAR_ROUND_KEY);
  const [isInRR, setIsInRR] = useState(isInRoundRobin(defaultTeam));
  const handleRoundChange = (round) => {
    setCurrentRound(round)
  }

  useEffect(() => {
    setIsInRR(isInRoundRobin(defaultTeam))
    setCurrentRound(isInRoundRobin(defaultTeam) ? ROUND_ROBIN_KEY : REGULAR_ROUND_KEY)
  }, [defaultTeam]);

  return (
    <>
      <div className="pt-32">
        <div className="flex items-center justify-center align-middle pb-4">
          <img src={getTeamLogo(defaultTeam).default} style={style} alt="..."></img>
        </div>
        <hr className="my-6 mx-auto border-main-medium w-3/4" />
        {isInRR && <div className="w-full px-4">
        <GenericSetup rounds={ROUNDS} 
              handleRoundChange={handleRoundChange}
              selectedRound={currentRound}
              team={defaultTeam}
          />
        </div>}
        <RecapPerTeam team={defaultTeam} round={currentRound}/> 
        <GamesPerTeamTable team={defaultTeam} round={currentRound}/>
        <hr className="my-6 mx-auto border-main-medium w-3/4" />
        <HeaderStats style={{padding: "pb-2 pt-2"}} stats={getTeamStats(defaultTeam, currentRound)} team={getTeamProperty(defaultTeam, 'name')}/>
        <div className="flex flex-wrap">
          <div className="w-full mb-12 px-4">
            <RosterTable title="jugadoras" team={getTeamProperty(defaultTeam, 'name')} data={getTeamPlayers(defaultTeam)} addTitle={true} isInRR={isInRR}/>
            <RosterTable title="técnicos" team={getTeamProperty(defaultTeam, 'name')} data={getTeamCoaches(defaultTeam)}/>
          </div>
        </div> 
        <RosterFooter link={getTeamProperty(defaultTeam, 'link')} username={getTeamProperty(defaultTeam, 'username')}/>
      </div>
    </>
  );
}
