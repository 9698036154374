import React, {useState, createRef, useEffect} from "react";
import { useLocation } from "react-router-dom";
import { createPopper } from "@popperjs/core";

const DocumentsDropdown = () => {
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = createRef();
  const popoverDropdownRef = createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const location = useLocation();

  useEffect(() => {
    setDropdownPopoverShow(false)
  }, [location]);


  return (
    <>
      <a
        className="hover:text-main-light text-main-dark px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
        href="#stats"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <i className="text-main-dark far fa-file-alt text-lg leading-lg mr-2" />{" "}
        Documentos
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <a
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-main-light h-45-px"
            href="https://drive.google.com/file/d/12eq-YDKkQ7Lpn3XPxh1f56RglDGhevHm/view?usp=drive_link"
            target="_blank" rel="noreferrer"
        >
        Reglamento Oficial de Kickingball
        </a>
        <a
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-main-light h-45-px"
            href="https://drive.google.com/file/d/1aozcCereulolEXTG4c0UPjXN172DyTQp/view?usp=drive_link"
            target="_blank" rel="noreferrer"
        >
        Condiciones de la LKM 2024
        </a>
      </div>
    </>
  );
};

export default DocumentsDropdown;
