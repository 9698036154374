import React from 'react';

import { getSelectorClass } from 'components/Setups/setupUtils'
import { FINAL_ROUND_KEY } from 'leagueConstants';

export default function GenericSetup({
    rounds,
    handleRoundChange,
    selectedRound,
    team
}) {

    const mainRounds = JSON.parse(JSON.stringify(rounds))
    delete mainRounds[FINAL_ROUND_KEY]
  return (
    <>
        <div className="relative pb-4">
            <div>
                <ul className="flex pl-0 flex-wrap">
                    {Object.keys(mainRounds).map((round) => (
                        <li key={round} className="pr-1">
                            <a href={team ? ("#" +  (team).replace(' ', '')) : "#round"} onClick={() => handleRoundChange(round)} 
                                className={getSelectorClass(round, selectedRound)}>
                                {mainRounds[round].v}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </>
  );
}
