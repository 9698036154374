import React from "react";
import {getGamesOfTeam} from 'leagueUtils'
import CardNextGame from "components/Cards/CardNextGame";
import { REGULAR_ROUND_KEY, ROUND_ROBIN_KEY } from "leagueConstants";

export default function GamesPerTeamTable({team, round = REGULAR_ROUND_KEY}) {
    const games = getGamesOfTeam(team, round)
    const titleMap = {
        [REGULAR_ROUND_KEY]: "Resultados Ronda Regular",
        [ROUND_ROBIN_KEY]: "Resultados Round Robin"
    }
    return (
        <>
            {!!games.length && <div className="w-full pt-2 pb-2 px-4">
                <div className="rounded-t mb-0 pb-4 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full max-w-full flex-grow flex-1">
                            <h3 className="font-bold text-lg text-main-dark-opacity uppercase">{titleMap[round]}</h3>
                        </div>
                    </div>
                </div >
                <div className="flex flex-wrap justify-center">
                    {
                        games.map(g => (
                              <div key={(g.date + g.hour)} className="w-1/2 lg:w-2/12 px-1 py-1">
                                <CardNextGame
                                    team1={g.homeClub}
                                    team2={g.visitor}
                                    time={g.hour}
                                />
                            </div>
                        ))
                    }
                </div>
                
            </div>}
        </>
    );
}