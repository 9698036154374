import React from 'react';

export default function RosterTable({
    data,
    title, team, addTitle, isInRR = false
}) {
  return (
    <>
      <div className="relative flex flex-col justify-center min-w-0 break-words w-full mb-6">
        {addTitle && <div className="rounded-t mb-0 pb-4 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h3 className="font-bold text-lg text-main-dark-opacity uppercase">Roster</h3>
            </div>
          </div>
          {isInRR &&<div>Jugadoras con * no pueden jugar el round robin</div>}
        </div>}
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse rounded bg-main-opacity">
            <thead>
              <tr>
                <th className="px-6 py-3 align-middle text-sm uppercase whitespace-nowrap font-semibold text-center bg-blueGray-100 text-main-dark rounded">
                    {title}
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data.map(person => (
                  <tr key={person.name}>
                    <td className="px-2 py-4 border-l-0 border-r-0 border-t-0 border-b-1 text-sm whitespace-nowrap text-center">
                      <div className="flex items-center justify-center w-full">
                        {/* <img src={getLogo(team).default} className="h-8 w-8 bg-white" alt="..."></img> */}
                        <span className="ml-2 font-bold text-main-dark">{person.name}</span>
                        {person.shirt && (
                          <div>
                            {/* <span className="ml-2 font-bold text-blueGray-600 uppercase">{" #"}</span> */}
                            {/* <i className="fa-solid fa-shirt"></i> */}
                            <span className="ml-2 font-bold text-main-dark">{" # " + person.shirt}</span>
                          </div>
                          
                        )} {person.canNotPlay && 
                          <div>
                            <span className="ml-2 font-bold text-main-dark"> *</span>
                          </div>
                        }
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
