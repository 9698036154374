import { getTeamLogo } from "leagueUtils";
import React from "react";

export default function PodiumTable() {
  return (
    <>
      <div className="w-full pt-8 px-4">
            <div className="podium-grid-wrapper">
                <div className="pl-2 bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: '1/4', gridColumn: 2}}>
                    <p className="text-md text-main-dark text-center">
                        <img src={getTeamLogo("Panteras").default} className="h-22 w-22" alt="..."></img>
                    </p>
                </div>
                <div className="pl-2 bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: '2/4', gridColumn: 1}}>
                    <p className="text-md text-main-dark text-center">
                        <img src={getTeamLogo("Vikingas").default} className="h-20 w-20" alt="..."></img>
                    </p>
                </div>
                <div className="pl-2 bg-main-opacity rounded-mini flex items-center justify-center"
                    style={{gridRow: 3, gridColumn: 3}}>
                    <p className="text-md text-main-dark text-center">
                        <img src={getTeamLogo("Espartanas").default} className="h-16 w-16" alt="..."></img>
                    </p>
                </div>
            </div>
      </div>
    </>
  );
}