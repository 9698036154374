import React from "react";

import { getTeamLogo } from 'leagueUtils'

export default function CardNextGame({
  team1, team2, time, date
}) {
  let newDate
  if (date) {
    newDate = date.replace("/2024", "")
    newDate = newDate.split("/")
    newDate = newDate[1] + '/' + newDate[0]
  }
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-main-opacity rounded mb-6 xl:mb-0 shadow-lg ">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-auto pr-2 flex-initial">
              <div
                className="p-3 inline-flex items-center justify-center align-middle"
              >
                <img src={getTeamLogo(team1).default} alt="..."
                    className="h-10 w-10 align-middle justify-center border-none absolute max-w-150-px"/>
              </div>
            </div>
            <div className="relative w-full max-w-full flex-grow flex-1 text-center">
              <span className="font-semibold text-md text-main-dark">
                {time}
              </span>
              <h5 className="text-main-medium-light uppercase font-bold text-xs">
                {newDate}
              </h5>
            </div>
            <div className="relative w-auto pl-2 flex-initial">
              <div
                className="p-3 inline-flex items-center justify-center align-middle"
              >
                <img src={getTeamLogo(team2).default}  alt="..."
                    className="h-10 w-10 align-middle justify-center border-none absolute max-w-150-px"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}